/**
 * @generated SignedSource<<5803be07104daf1396f4915229dc75b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type plusWidgetHighlightsQuery$data = {
  readonly highlightQueue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"plusWidgetHighlight">;
        };
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "plusWidgetHighlightsQuery";
};
export type plusWidgetHighlightsQuery$key = {
  readonly " $data"?: plusWidgetHighlightsQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"plusWidgetHighlightsQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "queueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "plusWidgetHighlightsQuery",
  "selections": [
    {
      "alias": "highlightQueue",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "queueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 2
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "plusWidgetHighlight"
                        }
                      ],
                      "type": "Content",
                      "abstractKey": "__isContent"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:2)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "25d4290d40df13e047b80447d0c28f96";

export default node;
