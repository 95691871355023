import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

export const Container = styled.div``;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-block: 16px;
`;

export const MoreOnLink = styled(BaseLink)`
  display: flex;
  gap: 4px;
  align-items: center;

  color: #8ab2ff;
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16.41px;

  &:hover {
    text-decoration: underline;
  }
`;
