/**
 * @generated SignedSource<<b3329bd9f028571d8f24c86414cf7ad3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type plusWidgetHighlight$data = {
  readonly __typename: string;
  readonly entityId: string;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "helpersCheckIsPlusArticle" | "helpersGetArticleTypeArticle" | "hooksContentItemProvidersContent" | "plusArticleTypeTitleContent">;
  readonly " $fragmentType": "plusWidgetHighlight";
};
export type plusWidgetHighlight$key = {
  readonly " $data"?: plusWidgetHighlight$data;
  readonly " $fragmentSpreads": FragmentRefs<"plusWidgetHighlight">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "plusWidgetHighlight",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsPlusArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PaywallType",
              "kind": "LinkedField",
              "name": "paywallTypes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "entityUuid",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersGetArticleTypeArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArticleTypeSegment",
              "kind": "LinkedField",
              "name": "types",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ArticleType",
                  "kind": "LinkedField",
                  "name": "value",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize540x360",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSections",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "plusArticleTypeTitleContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "c04cdae5b4a2c7760fa2e6ab3ec30a35";

export default node;
