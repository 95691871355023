/**
 * @generated SignedSource<<c72d83cd394d6e3dd31a5b9d7cf9b4c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type mostPopularQueueQuery$data = {
  readonly mostPopularQueueQuery: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"homeSecondaryContentItemContent">;
        };
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "mostPopularQueueQuery";
};
export type mostPopularQueueQuery$key = {
  readonly " $data"?: mostPopularQueueQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"mostPopularQueueQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "mostPopularQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "mostPopularQueueQuery",
  "selections": [
    {
      "alias": "mostPopularQueueQuery",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "mostPopularQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 5
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "homeSecondaryContentItemContent"
                        }
                      ],
                      "type": "Content",
                      "abstractKey": "__isContent"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:5)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "38a072a45d34e0ccb4ede8324be914d5";

export default node;
