/**
 * @generated SignedSource<<1f7b0eadb86dfcb05e80b7330720d86b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type plusWidgetDailyPulseQuery$data = {
  readonly dailyPulseQuery: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly entityId: string;
        readonly urlAlias: string;
        readonly " $fragmentSpreads": FragmentRefs<"contentHeadlineContent" | "entityLink" | "hooksArticleDateUtilsContent" | "hooksPlusArticleLinkHandlerArticle">;
      };
    }>;
  };
  readonly " $fragmentType": "plusWidgetDailyPulseQuery";
};
export type plusWidgetDailyPulseQuery$key = {
  readonly " $data"?: plusWidgetDailyPulseQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"plusWidgetDailyPulseQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlAlias",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SectionSegment",
    "kind": "LinkedField",
    "name": "sections",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "value",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dailyPulseTypeUuId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "plusWidgetDailyPulseQuery",
  "selections": [
    {
      "alias": "dailyPulseQuery",
      "args": [
        {
          "fields": [
            {
              "items": [
                {
                  "kind": "Variable",
                  "name": "typeIds.0",
                  "variableName": "dailyPulseTypeUuId"
                }
              ],
              "kind": "ListValue",
              "name": "typeIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "DESC",
            "field": "PUBLISHED_DATE"
          }
        }
      ],
      "concreteType": "ArticleConnection",
      "kind": "LinkedField",
      "name": "articles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Article",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "entityLink"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "contentHeadlineContent"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "hooksArticleDateUtilsContent"
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "hooksPlusArticleLinkHandlerArticle",
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "helpersCheckCanShowInPlusPlatformContent",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            },
                            {
                              "kind": "InlineDataFragmentSpread",
                              "name": "helpersCheckArticleIsInSectionsArticle",
                              "selections": (v3/*: any*/),
                              "args": null,
                              "argumentDefinitions": []
                            },
                            {
                              "kind": "InlineDataFragmentSpread",
                              "name": "helpersCheckIsPlusArticle",
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "PaywallType",
                                      "kind": "LinkedField",
                                      "name": "paywallTypes",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "entityUuid",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "Article",
                                  "abstractKey": null
                                }
                              ],
                              "args": null,
                              "argumentDefinitions": []
                            },
                            {
                              "kind": "InlineDataFragmentSpread",
                              "name": "helpersCheckIsStyleArticle",
                              "selections": (v3/*: any*/),
                              "args": null,
                              "argumentDefinitions": []
                            },
                            {
                              "kind": "InlineDataFragmentSpread",
                              "name": "helpersGetArticleTypeArticle",
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "ArticleTypeSegment",
                                      "kind": "LinkedField",
                                      "name": "types",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "ArticleType",
                                          "kind": "LinkedField",
                                          "name": "value",
                                          "plural": true,
                                          "selections": (v2/*: any*/),
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "Article",
                                  "abstractKey": null
                                }
                              ],
                              "args": null,
                              "argumentDefinitions": []
                            },
                            {
                              "kind": "InlineDataFragmentSpread",
                              "name": "helpersUseContentSponsorContent",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Topic",
                                  "kind": "LinkedField",
                                  "name": "topics",
                                  "plural": true,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Sponsor",
                                      "kind": "LinkedField",
                                      "name": "sponsor",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "args": null,
                                          "kind": "FragmentSpread",
                                          "name": "sponsorTagSponsor"
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "name",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "type",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "sponsorType",
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "Article",
                                  "abstractKey": null
                                },
                                {
                                  "kind": "InlineDataFragmentSpread",
                                  "name": "helpersCheckIsMainTopicContent",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Topic",
                                      "kind": "LinkedField",
                                      "name": "topics",
                                      "plural": true,
                                      "selections": (v2/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "args": null,
                                  "argumentDefinitions": []
                                }
                              ],
                              "args": null,
                              "argumentDefinitions": []
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ],
                      "type": "Content",
                      "abstractKey": "__isContent"
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e8b9b83b58df27218d91347abe76e920";

export default node;
