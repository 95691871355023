/**
 * @generated SignedSource<<2f77ff4210c0f1f328e90903d0be9a84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type chinaScienceDiscoveryWidgetQuery$data = {
  readonly chinaScienceQueue: {
    readonly " $fragmentSpreads": FragmentRefs<"latestDiscoveryWidgetQueue">;
  } | null | undefined;
  readonly chinaScienceSection: {
    readonly " $fragmentSpreads": FragmentRefs<"latestDiscoveryWidgetSection">;
  };
  readonly " $fragmentType": "chinaScienceDiscoveryWidgetQuery";
};
export type chinaScienceDiscoveryWidgetQuery$key = {
  readonly " $data"?: chinaScienceDiscoveryWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"chinaScienceDiscoveryWidgetQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "chinaScienceDiscoveryWidgetQuery",
  "selections": [
    {
      "alias": "chinaScienceSection",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "328475"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "latestDiscoveryWidgetSection"
        }
      ],
      "storageKey": "section(filter:{\"entityId\":\"328475\"})"
    },
    {
      "alias": "chinaScienceQueue",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "name": "section_top_328475"
          }
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "latestDiscoveryWidgetQueue"
        }
      ],
      "storageKey": "queue(filter:{\"name\":\"section_top_328475\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4739642741e0aa2f896271d85c01301f";

export default node;
