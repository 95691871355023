/**
 * @generated SignedSource<<57c24b420e5258d2869f9b9ecdb56909>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type entityFollowMenuQueueItemsEdge$data = ReadonlyArray<{
  readonly node: {
    readonly name?: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  };
  readonly " $fragmentType": "entityFollowMenuQueueItemsEdge";
}>;
export type entityFollowMenuQueueItemsEdge$key = ReadonlyArray<{
  readonly " $data"?: entityFollowMenuQueueItemsEdge$data;
  readonly " $fragmentSpreads": FragmentRefs<"entityFollowMenuQueueItemsEdge">;
}>;

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "entityFollowMenuQueueItemsEdge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Topic",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Section",
          "abstractKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityLink"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QueueItemsEdge",
  "abstractKey": null
};
})();

(node as any).hash = "4425a1aeb6c79eeb965aaff5b6f9f898";

export default node;
