/**
 * @generated SignedSource<<09c2c995043c9e3733ec20615a8438cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionTopLeadSecondaryContentItemContent$data = {
  readonly entityId: string;
  readonly topics: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"followButtonBase">;
  } | null | undefined> | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"contentCoverImageContent" | "entityLink" | "helpersCheckHasLiveTagArticle" | "helpersUseContentSponsorContent" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "sectionTopLeadSecondaryContentItemContent";
};
export type sectionTopLeadSecondaryContentItemContent$key = {
  readonly " $data"?: sectionTopLeadSecondaryContentItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionTopLeadSecondaryContentItemContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionTopLeadSecondaryContentItemContent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "followButtonBase"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withSize1200x800",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSize540x360",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "contentCoverImageContent"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersUseContentSponsorContent",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "topics",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Sponsor",
              "kind": "LinkedField",
              "name": "sponsor",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "sponsorTagSponsor"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sponsorType",
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsMainTopicContent",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "topics",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckHasLiveTagArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "flattenTypeIds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LiveArticle",
              "kind": "LinkedField",
              "name": "liveArticle",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withActionBar",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withLiveTag",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSections",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSponsorTag",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSummary",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withTopicLink",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withVideoDurationOverlay",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "5a525c4829aa4cc345145ade699fb6c3";

export default node;
