import { PageType } from "@product/scmp-sdk";
import last from "lodash/last";
import type { FunctionComponent } from "react";
import { useRef } from "react";
import { graphql, useFragment } from "react-relay";

import { useCurrentPageType } from "shared/hooks";

import type { entityFollowMenuQueueItemsEdge$key } from "scmp-app/queries/__generated__/entityFollowMenuQueueItemsEdge.graphql";

import { Container, MenuItemsContainer, StyledEntityLink, StyledRightArrowIcon } from "./styles";

type Props = {
  className?: string;
  onClick?: (itemLabel: string) => void;
  reference: entityFollowMenuQueueItemsEdge$key;
};

export const EntityFollowMenu: FunctionComponent<Props> = ({ className, onClick, reference }) => {
  const items = useFragment(
    graphql`
      fragment entityFollowMenuQueueItemsEdge on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Topic {
            name
          }
          ... on Section {
            name
          }
          ...entityLink
        }
      }
    `,
    reference,
  );

  const menuItemsReference = useRef<HTMLDivElement>(null);
  const currentPageType = useCurrentPageType();
  const handleRightArrowIconClick = () => {
    if (menuItemsReference.current) {
      menuItemsReference.current.scrollLeft += menuItemsReference.current.clientWidth;
    }
  };

  const cleanUpLabel = (string_?: string) => {
    const label = last(string_?.split(":") ?? []) ?? "";
    return label.trim();
  };

  const getCustomParameter = () => {
    if (currentPageType === PageType.Section) {
      return {
        module: `sub_section_menu`,
        pgtype: currentPageType,
      };
    }

    return {};
  };

  return (
    <Container className={className}>
      <MenuItemsContainer ref={menuItemsReference}>
        {items.map(({ node }, index) => {
          const customParameter = getCustomParameter();
          return (
            <StyledEntityLink
              key={index}
              onClick={() => {
                onClick?.(cleanUpLabel(node.name));
              }}
              query={customParameter}
              reference={node}
            >
              {cleanUpLabel(node.name)}
            </StyledEntityLink>
          );
        })}
      </MenuItemsContainer>
      <StyledRightArrowIcon onClick={handleRightArrowIconClick} />
    </Container>
  );
};

EntityFollowMenu.displayName = "EntityFollowMenu";
