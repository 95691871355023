import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { ContentBody } from "scmp-app/components/content/content-body";
import { ContentHeadline } from "scmp-app/components/content/content-headline";
import { EntityLink } from "scmp-app/components/entity-link";

export const StyledContentBody = styled(ContentBody)``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  font-family: ${fontRobotoCondensed};
  ${theme.breakpoints.between("tablet", "desktop")} {
    margin-inline-end: 24px;
    border-block-end: 1px solid rgba(255, 255, 255, 0.3);

    padding-block-end: 20px;
  }
`;

export const StyledContentHeadline = styled(ContentHeadline)`
  margin-block: 12px;

  font-weight: 700;
  font-size: 20px;
  line-height: 23.44px;
`;

export const LastUpdatedDate = styled.div`
  margin-block-end: 16px;

  font-size: 12px;
  span {
    font-weight: 700;
  }
`;

export const Content = styled.div`
  display: -webkit-box;

  margin-block-end: 16px;

  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  white-space: pre-wrap;
  text-overflow: ellipsis;

  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-clamp: 5; /* stylelint-disable-line property-no-unknown */
`;

export const ReadFullDailyPulseLink = styled(EntityLink)`
  padding-block: 8px;

  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 20.8px;
  text-align: center;

  border-radius: 2px;

  background: #4585ff;

  &:hover {
    text-decoration: underline;
  }
`;
