/**
 * @generated SignedSource<<ae525be5af070bcec18cc471f8b9244f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type plusWidgetNewsAgendaQuery$data = {
  readonly topics: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly newsAgenda: {
          readonly newsAgendaOptions: ReadonlyArray<string>;
        } | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"hooksNewsAgendaTopic" | "itemAgendaItemTopic">;
      };
    }>;
  };
  readonly " $fragmentType": "plusWidgetNewsAgendaQuery";
};
export type plusWidgetNewsAgendaQuery$key = {
  readonly " $data"?: plusWidgetNewsAgendaQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"plusWidgetNewsAgendaQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "applicationId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plusNewsAgendaEndDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plusNewsAgendaStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plusNewsAgendaTypes"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "plusWidgetNewsAgendaQuery",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "applicationId",
          "variableName": "applicationId"
        },
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "endDate",
                  "variableName": "plusNewsAgendaEndDate"
                },
                {
                  "kind": "Variable",
                  "name": "startDate",
                  "variableName": "plusNewsAgendaStartDate"
                }
              ],
              "kind": "ObjectValue",
              "name": "newsAgendaDateRange"
            },
            {
              "kind": "Literal",
              "name": "newsAgendaOptions",
              "value": [
                "key_event"
              ]
            },
            {
              "kind": "Variable",
              "name": "types",
              "variableName": "plusNewsAgendaTypes"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 5
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "ASC",
            "field": "NEWS_AGENDA_START_DATE"
          }
        }
      ],
      "concreteType": "TopicConnection",
      "kind": "LinkedField",
      "name": "topics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TopicEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "itemAgendaItemTopic"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "hooksNewsAgendaTopic"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NewsAgenda",
                  "kind": "LinkedField",
                  "name": "newsAgenda",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "newsAgendaOptions",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "ace43df5892ed021133ca612542cb0e5";

export default node;
