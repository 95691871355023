import { FollowType, PageType } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { section as sectionData } from "shared/data/section";
import { useCurrentPageType } from "shared/hooks";

import { EntityFollowMenu } from "scmp-app/components/entity-follow-menu";
import type { subsectionMenuQueue$key } from "scmp-app/queries/__generated__/subsectionMenuQueue.graphql";
import type { subsectionMenuSection$key } from "scmp-app/queries/__generated__/subsectionMenuSection.graphql";

import {
  Container,
  SectionBadge,
  SectionName,
  StyledHomeFollowButton,
  StyledStyleIcon,
  Subsection,
} from "./styles";

type Props = {
  reference: subsectionMenuSection$key;
  subSectionReference?: subsectionMenuQueue$key;
};

export const SubsectionMenu: FunctionComponent<Props> = ({ reference, subSectionReference }) => {
  const section = useFragment(
    graphql`
      fragment subsectionMenuSection on Section {
        ...followButtonBase
        entityId
        name
        urlAlias
      }
    `,
    reference,
  );

  const subsections = useFragment(
    graphql`
      fragment subsectionMenuQueue on QueueItemsEdge @relay(plural: true) {
        ...entityFollowMenuQueueItemsEdge
      }
    `,
    subSectionReference ?? null,
  );

  const isStyleSection = section.entityId === sectionData.style.entityId;
  const currentPageType = useCurrentPageType();

  return (
    <Container>
      <SectionBadge>
        <SectionName pathname={section.urlAlias}>
          {isStyleSection ? <StyledStyleIcon /> : section.name}
        </SectionName>
        <StyledHomeFollowButton
          reference={section}
          source={currentPageType === PageType.Section ? "Section" : "HP Section Block"}
          type={FollowType.Section}
        />
      </SectionBadge>
      {subsections?.length && (
        <Subsection>
          <EntityFollowMenu reference={subsections} />
        </Subsection>
      )}
    </Container>
  );
};

SubsectionMenu.displayName = "SubsectionMenu";
