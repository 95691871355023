/**
 * @generated SignedSource<<00e5c08656497c0af773b3d1a9df043d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subsectionMenuQueue$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"entityFollowMenuQueueItemsEdge">;
  readonly " $fragmentType": "subsectionMenuQueue";
}>;
export type subsectionMenuQueue$key = ReadonlyArray<{
  readonly " $data"?: subsectionMenuQueue$data;
  readonly " $fragmentSpreads": FragmentRefs<"subsectionMenuQueue">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "subsectionMenuQueue",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityFollowMenuQueueItemsEdge"
    }
  ],
  "type": "QueueItemsEdge",
  "abstractKey": null
};

(node as any).hash = "041f076a57ead10e158f90d4141608f7";

export default node;
