import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";

export const Container = styled.div`
  display: grid;
  grid:
    "logo-plus" min-content
    "daily-pulse" min-content
    "news-agenda" min-content
    "highlights-from-scmp-plus" min-content
    / 1fr;

  ${theme.breakpoints.between("tablet", "desktop")} {
    grid:
      "logo-plus logo-plus" min-content
      "daily-pulse highlights-from-scmp-plus" min-content
      "news-agenda highlights-from-scmp-plus" 1fr
      / 1fr 1fr;

    column-gap: 24px;
  }
`;
export const LogoPlusContainer = styled.div`
  grid-area: logo-plus;

  margin-block-end: 16px;
  padding-block-end: 12px;
  border-block-end: 1px solid rgba(255, 255, 255, 0.3);
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16.41px;
  text-transform: uppercase;
`;

export const DailyPulse = styled.div`
  grid-area: daily-pulse;

  margin-block-end: 16px;
  padding-block-end: 16px;
  border-block-end: 1px solid rgba(255, 255, 255, 0.3);

  ${theme.breakpoints.between("tablet", "desktop")} {
    margin-block-end: 0;
    padding-block-end: 0;
    border-inline-end: 1px solid rgba(255, 255, 255, 0.3);
    border-block-end: 0;
  }
`;

export const NewsAgenda = styled.div`
  grid-area: news-agenda;

  margin-block-end: 16px;
  padding-block-end: 16px;
  border-block-end: 1px solid rgba(255, 255, 255, 0.3);

  ${theme.breakpoints.between("tablet", "desktop")} {
    margin-block-end: 0;
    padding-block: 20px 0;
    padding-inline-end: 24px;
    border-block-end: 0;
    border-inline-end: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

export const HighlightsFromScmpPlus = styled.div`
  grid-area: highlights-from-scmp-plus;
`;
